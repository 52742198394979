.main-header {
  box-shadow: 1px 1px 5px #888;
  padding: 20px;

  &__logos {
    img {
      display: inline-block;
      margin-right: 20px;
      max-height: 65px;
      vertical-align: middle;
    }
  }
}